import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MessageService } from './message.service';
import { Observable } from 'rxjs';
declare let Email: any;
@Injectable()
export class SendMailService {

  constructor(private _http: HttpClient, private toastr: MessageService, private router: Router) { }

  sendMessage(body:SendMailRequest): Observable<EmailResponse> {
    const url = 'https://api.smtp2go.com/v3/email/send'
    const headers = { 'Content-Type': "application/json" };
    return this._http.post<EmailResponse>(url, body, { headers });
  }

  getMailsByClientEmail(body: SearchEmail) {
    const headers = { 'Content-Type': "application/json" };
    return this._http.post<SearchMailResponse>(`https://api.smtp2go.com/v3/email/search`, body, { headers });
  }


}

export interface SendMailRequest {
  api_key: string
  to: string[]
  sender: string
  subject: string
  text_body?: string
  html_body?: string
  custom_headers?: CustomHeader[]
  attachments?: Attachment[]
}

export interface CustomHeader {
  header: string
  value: string
}

export interface Attachment {
  filename: string
  fileblob: string
  mimetype: string
}


export interface Data {
  succeeded: number;
  failed: number;
  failures: any[];
  email_id: string;
}

export interface EmailResponse {
  request_id: string;
  data: Data;
}


export class SearchEmail {
  api_key: string;
  filter_query: string;//"subject:Test AND recipient:test@example.com"
  limit?: number;
  end_date?: string;//"2016-06-26"
  start_date?: string;//"2016-06-26"
  username?: string;
}


export interface SearchMailResponse {
  request_id: string;
  data: SearchMailData;
}

export interface SearchMailData {
  count: number;
  emails: Email[];
  continue_token: string;
}

export interface Email {
  subject: string
  delivered_at: string
  process_status: string
  email_id: string
  status: string
  response: string
  email_ts: string
  host: string
  smtpcode: number
  sender: string
  recipient: string
  username: string
  total_opens: number
  opens: any[]
  total_clicks: number
  clicks: any[]
}

export interface Open {
  recipient: string;
  opened_at: string;
  ip_address: string;
  user_agent: string;
  read_secs: string;
}
