import { Injectable, Injector } from '@angular/core';
import { Route, Router } from '@angular/router';

export class RouteConfig {
  path: string;
  children?:RouteConfig[]
}

@Injectable({
  providedIn: 'root'
})
export class RouteServiceService {

  constructor() { }

  loadRouterConfig(router: Router,injector: Injector):RouteConfig[] {
    return router.config.reduce(
      (acc, route) => {
        const children = [];

        const rec = (rout: Route, arr) => {
          const child: any[] = [];
          if (rout.loadChildren) {
            (<any>router).configLoader.load(injector, rout).subscribe({
              next: (moduleConf: any) => {
                moduleConf.routes?.forEach(childRoute => {
                  if (childRoute?.children?.length >= 1) {
                    childRoute?.children?.forEach(crout => {
                      if(crout?.loadChildren){
                        child.push({
                          path: crout.path,
                          children: rec(crout, [])
                        })
                      }
                      else{
                        child.push({
                          path: crout.path,
                        })
                      }
                    })
                  }
                  else {
                    child.push({
                      path: childRoute.path,
                    })
                  }
                });
              }
            });
          }
          arr.push({
            path: rout.path,
            children:child
          })
          return arr
        }

        if (route.loadChildren) {
          (<any>router).configLoader.load(injector, route).subscribe({
            next: (moduleConf) => {
              moduleConf.routes?.forEach(childRoute => {
                if (childRoute?.children?.length >= 1) {
                  childRoute?.children?.forEach(crout => {
                    children.push(rec(crout, []));
                  })
                }
                children.push(childRoute.path);
              });
            }
          });
        }
        acc.push({
          path: route.path,
          children
        })
        return acc;
      },[]);
  }
}
