import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MessageService } from './message.service';
import { Observable } from 'rxjs';
declare let Email: any;
@Injectable()
export class RequestPaymentService {

  constructor(private _http: HttpClient , private toastr:MessageService,private router:Router) {}

  sendPayment(body):Observable<any> {
      const url = 'https://api.na.bambora.com/v1/payments'
      const headers = {"Authorization" : " Passcode 983e3Edf9687439B989343E670a1e7b4",'Content-Type': "application/json"};
      return this._http.post(url, body, { headers });

      
}
}