import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import(`./login/login.module`).then(m => m.LoginModule)
  },
  {
    path: 'error',
    loadChildren: () => import(`./error/error.module`).then(m => m.ErrorModule)
  },
  {
    path: 'credit-card',
    loadChildren: () => import(`./credit-card/credit-card.module`).then(m => m.CreditCardModule)
  },
  {
    path: '',
    loadChildren: () => import(`./secure/secure.module`).then(m => m.SecureModule),
  },
  {
    path: '**',
    redirectTo:'error/error404'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
